.lumi-header {
  padding: 20px;
  height: 100vh;
  //   border: 1px solid black;
  display: flex;
  align-items: center;
  background-color: aliceblue;

  &--main {
    border: 1px solid white;
    border-radius: 20px;
    max-width: 80%;
    margin: auto;
    text-align: center;
    padding: 20px;
    background-color: white;
    box-shadow: 5px 8px 10px;
  }

  &--sub-main {
    display: flex;
    justify-content: space-around;

    li {
      list-style: none;
      text-align: left;
      a {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .lumi-header {
    padding: 10px;
    &--main {
      max-width: 95%;
      border: 1px solid red;
    }

    &--sub-main {
      h5 {
        text-align: left;
      }
    }
  }
}
